import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/builds/hb-marketplace/checkout/checkout-frontend/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/builds/hb-marketplace/checkout/checkout-frontend/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/builds/hb-marketplace/checkout/checkout-frontend/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/builds/hb-marketplace/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_environment_ts_6jcoV7IFIi from "/builds/hb-marketplace/checkout/checkout-frontend/plugins/environment.ts";
import plugins_gtm_client_ts_OzmBuHLRIb from "/builds/hb-marketplace/checkout/checkout-frontend/plugins/gtm.client.ts";
import plugins_moneyFormatter_ts_T1WE8meYMK from "/builds/hb-marketplace/checkout/checkout-frontend/plugins/moneyFormatter.ts";
import plugins_sentry_ts_3AyO8nEfhE from "/builds/hb-marketplace/checkout/checkout-frontend/plugins/sentry.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_environment_ts_6jcoV7IFIi,
  plugins_gtm_client_ts_OzmBuHLRIb,
  plugins_moneyFormatter_ts_T1WE8meYMK,
  plugins_sentry_ts_3AyO8nEfhE
]