import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/address.vue?macro=true";
import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/index.vue?macro=true";
import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/pay.vue?macro=true";
import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/status.vue?macro=true";
import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/continue.vue?macro=true";
import { default as _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta } from "/builds/hb-marketplace/checkout/checkout-frontend/pages/test.vue?macro=true";
export default [
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta?.name ?? "checkout-id-address",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta?.path ?? "/checkout/:id/address",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/address.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47address_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/address.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta?.name ?? "checkout-id",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta?.path ?? "/checkout/:id",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/index.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta?.name ?? "checkout-id-pay",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta?.path ?? "/checkout/:id/pay",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/pay.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47pay_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta?.name ?? "checkout-id-status",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta?.path ?? "/checkout/:id/status",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/status.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47_91id_93_47status_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/[id]/status.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta?.name ?? "checkout-continue",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta?.path ?? "/checkout/continue",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/continue.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47checkout_47continue_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/checkout/continue.vue").then(m => m.default || m)
  },
  {
    name: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta?.name ?? "test",
    path: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta?.path ?? "/test",
    file: "/builds/hb-marketplace/checkout/checkout-frontend/pages/test.vue",
    children: [],
    meta: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta,
    alias: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta?.alias || [],
    redirect: _47builds_47hb_45marketplace_47checkout_47checkout_45frontend_47pages_47test_46vueMeta?.redirect || undefined,
    component: () => import("/builds/hb-marketplace/checkout/checkout-frontend/pages/test.vue").then(m => m.default || m)
  }
]